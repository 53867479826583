.element-hello{
  color: #000;
font-family: Poppins;
font-size: 45px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.element-info{
  color: #9F9F9F;
  text-align: center;
  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}

.element-card-scanner{
  margin-top: 20px !important;
  border-radius: 8px !important;
background: rgba(0, 163, 255, 0.16) !important;
}

.element-card-write{
  border-radius: 8px !important;
background: rgba(255, 184, 0, 0.16) !important;
margin-top: 20px !important;
}

.MuiCardHeader-title{
  padding-left: 20px;
  color: #000;
  font-family: Poppins !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
}

.element-qr-icon{
  width: 56px;
height: 56px;
flex-shrink: 0;
padding-left: 20px;
}

.pustota{
  width: 200px;
  height: 100px;
  color: white;
}