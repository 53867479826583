.element-product-name{

border-radius: 20px;
color: #000;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
padding-top: 10px;
padding-bottom: 20px;
background-color: rgba(79, 199, 255, 0.359);
}

.kod{
    
    margin-top: 20px;
    margin-right: 20px;
   
}

.nazwa{
    margin-left: 10px;
    font-size: 22px;
}

.flex-container {
    display: flex;
    align-items: center;
  }

  .nazwa{
    border-radius: 20px;
    color: #000;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-top: 20px;
    padding-bottom: 20px;
    padding: 20px;

  }
  