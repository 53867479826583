.knopka {
    background-color: #3FC500;
    color: white;
    border: 1px solid #3FC500;
    border-radius: 15px;
    padding: 10px 20px; 
    font-family: "Poppins";
    font-size: 16px; 
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
  
    &:hover {
      background-color: #2E9400; 
      border-color: #2E9400;
    }
  }
  