.box {
  border: 0px none;
  height: 102px;
  width: 369px;
}

.element-card-uwaga {
  margin-top: 20px !important;
  background-color: #ff00000f !important;
  border: 2px solid !important;
  border-color: #ff000042 !important;
  border-radius: 15px !important;
 
  
}

.i-push {
  height: 37px;



  width: 34px;
}

.element-card-uwaga .MuiCardHeader-title{
    color: #000 !important;
    font-family: Poppins !important;
    font-size: 13px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
}
