.element-lokal{
    color: #000;
font-family: Poppins;
font-size: 11px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.element-nr-zam{
    color: #000;
font-family: Poppins;
font-size: 11px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.element-local-name{
    color: #000;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.element-nr-zam-num{
    color: #000;
font-family: Poppins;
font-size: 45px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.element-product-name{
    color: #000;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
padding-top: 10px;
}

.element-product-other{
    color: #000;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
padding-top: 15px;
}

.status-ready{
    border-radius: 9px;
background: rgba(63, 197, 0, 0.10);
}

.status-preparing{
    border-radius: 9px;
background: rgba(255, 138, 0, 0.12);
}

.status-taken{
    border-radius: 9px;
    background: rgba(0, 163, 255, 0.12);
}

.szt {
    color: #BCBCBC;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.element-bill-status{
    color: rgba(255, 122, 0, 0.40);
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: normal;
border-radius: 8px;
background: rgba(255, 138, 0, 0.12);
height: 56px;
padding-top: 17px !important;
}

.element-bill-status-ready{
    color: rgba(0, 163, 255, 0.43);
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: normal;
border-radius: 8px;
background: rgba(63, 197, 0, 0.10);
height: 56px;
padding-top: 17px !important;
}

.element-bill-suma{
    color: rgba(0, 163, 255, 0.43);
font-family: Poppins;
font-size: 13px;
font-style: normal;
font-weight: 600;
line-height: normal;
border-radius: 9px;
background: rgba(0, 163, 255, 0.12);
height: 56px;
padding-top: 17px !important;
}

.element-suma{
    color: #000;
font-family: Poppins;
font-size: 30px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-left: 20px;
}

.kds{
    color: #222222;
    font-family: Poppins;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    
}
.kds-name{
    color: #0c0c0c;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
   
}

.logo{
    max-height: 90px !important;
}

.legenda {
    margin-left: 30px;
    color: #000;
font-family: Poppins;
font-size: 10px;
font-style: normal;
font-weight: 600;
line-height: normal;
padding-left: 10px;
}
  
.legenda-row {
    margin-top: 10px !important;
}


.liczba{
    font-family: Poppins;
    font-size: 28px;
    font-weight: 600;
}


.send{
margin-top: 20px;

flex-shrink: 0;
border-radius: 15px;
border: 2px solid #DDD;
background: rgba(255, 255, 238, 0.10);
display: grid;
align-items: center;
}


.prp-punkt{
    margin-top: 10px;
    display: grid;
    margin-left: 0px;
    
}


.przepis-punktow{
    border-radius: 50px;
    background: #3FC500;
    border: none;
    color: white;
    height: 40px;
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 27px; /* 245.455% */

}