.restaurant-list {
  padding: 20px;
}

.restaurant-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); 
  gap: 20px;
}

@media (min-width: 768px) {
  .restaurant-grid {
    grid-template-columns: repeat(5, 1fr); 
  }
}

.restaurant-item {
  margin-top: 20px;
  border-radius: 7px;
  border: 2px solid #EEE;
  text-align: center;
  padding: 10px;
}

img {
  max-width: 100%;
  height: auto;

}

.menu-list {
  list-style: none;
  display: grid;
  grid-template-columns: repeat( minmax(200px, 1fr));
  grid-gap: 10px;
  justify-content: center; 
  align-items: center; 
}


.category {
  padding: 20px;
  border-radius: 8px;
  margin: 5px; 
}

.categoryName {
  display: block;
  text-align: center;
  color: #FF7A00;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.daniat-list {
  list-style: none;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px; 
}

/*pizdim s etogo*/
.dania {
  color: #000;
  font-family: Poppins;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 10px;
  padding: 30px;
  border-radius: 8px;
  background: #F2F2F2;
}


.menu-list {
  list-style: none;
  display: grid;
  grid-template-columns: repeat( minmax(100px, 1fr));
  grid-gap: 5px;
  justify-content: center; 
  align-items: center; 
}


.category {
  padding: 20px;
  border-radius: 8px;
  margin: 5px; 
}

.categoryName {
  display: block;
  text-align: center;
  color: #FF7A00;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.daniat-list {
  list-style: none;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 5px; 
}

.dania {
  color: #000;
  font-family: Poppins;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 15px;
  padding: 40px;
  border-radius: 8px;
  background: #F2F2F2;
}


.container {
  display: grid;
  grid-template-columns: 25% 0.5fr; 
  align-items: center; 
  gap: 20px; 
  margin-top: 30px;
  margin-left: 20px;
}

.search-input {
  display: block;
  background-color: transparent;
  border: none;
  border: 1px solid #ccc;
  padding: 10px 0;
  transition: border-color 0.7s ease, box-shadow 0.3s ease;
  border-radius: 20px;
  margin: 0 0px;
  width: calc(100% - 5px);
}

.search-input input {
  margin-left: 10px;
  border: none;
  outline: none;
  width: 90%;
  padding: 0;
  color: #000;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.search-input:hover {
  border-color: #FF7A00;
  box-shadow: 0 1px 0 0 #FF7A00;
}

.search-input input::placeholder {
  padding-left: 10px; 
}


.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh; 
}

.restaurant-item {
  position: relative;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.restaurant-item:hover {
  transform: scale(1.1); 
}


.buy-for-punkts{
  margin-left: 55%;
  width: 143px;
  height: 31px;
  flex-shrink: 0;
  color: #FFF;
  font-family: Poppins;
  font-size: 12px;
  text-align: center;
  white-space: nowrap;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: #00A3FF;
  border-radius: 55px;
  background: #00A3FF;
}


.description{
  color: #7A7A7A;
font-family: Poppins;
font-size: 11px;
font-style: normal;
font-weight: 400;
line-height: 15px; 
}


.fixed-button {
  color: #FFF;
text-align: center;
font-family: Poppins;
font-size: 21px;
font-style: normal;
font-weight: 700;
line-height: 27px; /* 128.571% */
  border: 1px;
  border-color: #3FC500;
  background-color: #3FC500;
  height: 70px;
  width: 340px;
  border-radius: 30px;
  position: fixed;
  top: 84%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3000;
}


.product-in-order{
  color: #000;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 10px;
  padding: 10px;
  border-radius: 8px;
  background: #3FC5001A;
}

.price-style {
  color: #2E9000;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
  text-align: right;
}

.transparent-button {
  color:rgba(255, 0, 0, 0.60);;
  font-size: 11px;
  background: none;
  border: none;
  cursor: pointer;
}
