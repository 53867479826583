.menu-list {
    list-style: none;
    display: grid;
    grid-template-columns: repeat( minmax(200px, 1fr));
    grid-gap: 10px;
    justify-content: center; 
    align-items: center; 
  }
  
  
  .category {
    padding: 10px;
    border-radius: 8px;
    margin: 5px; 
  }
  
  .categoryName {
    display: block;
    text-align: center;
    color: #FF7A00;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  
  .daniat-list {
    list-style: none;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 5px; 
  }
  
  .dania {
    color: #000;
    font-family: Poppins;
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 10px;
    padding: 10px;
    border-radius: 8px;
    background: #F2F2F2;
  }


  .container {
    display: grid;
    grid-template-columns: 25% 0.5fr; 
    align-items: center; 
    gap: 20px; 
    margin-top: 30px;
    margin-left: 20px;
  }
  

  