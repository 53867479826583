.custom-body-style{
    background: rgba(255, 184, 0, 0.12);

}

.element-nr{
    color: #000;
font-family: Poppins;
font-size: 28px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.element-info{
    color: #000;
font-family: Poppins;
font-size: 13px;
font-style: normal;
font-weight: 600;
line-height: normal;
}