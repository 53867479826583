

.obertka {
  border-radius: 15px;
  background: #F2F2F2;
  display: flex;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); 
  grid-gap: 0px;
  border: none;
  padding: 10px;
  margin-bottom: 10px;
  margin-right: 40px; 
  max-width: 325px; 
  flex-wrap: wrap;
  
}

.obertka-a {
  border: 3px;
  border-color: #3FC500;
  margin-left: 20px;
  border-radius: 15px;
  background: #F2F2F2;
  display: flex;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); 
  padding: 10px;
  max-width: 325px; 
  flex-wrap: wrap;
  
}


  
  .orderInfo {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
  }
  
  .orderInfo img {
    max-width: 60px;
    max-height: 60px;
  }
  
  .punkty {
    width: 62px;
    height: 23px;
    flex-shrink: 0;
    border-radius: 55px;
    background: #3FC500;
    color: #FFF;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;    
    text-align: center;
    margin-left: 10px;
  }
  
  .details {
    margin-top: 10px;
    padding: 5px 10px;
    background-color: #00A3FF;
    color: white;
    border: none;
    cursor: pointer;
  }
  
 
  
  
  .lista-produktow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  }
  
  @media screen and (max-width: 600px) {
    .orderInfo {
      grid-template-columns: 1fr; 
    }
  }

  .details{
    width: 110px;
    height: 29px;
    flex-shrink: 0;
    border-radius: 55px;
    background: #00A3FF;
  }
  