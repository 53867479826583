.center-container{
    display: flex;
    justify-content: center;
    
    height: 5vh;
    margin-top: 50px;
    margin-bottom: 20px;
}

.text{
    color: #000;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.centered-element {
    text-align: center;
    margin: 0 auto;
    display: block;
  }
  
.guide{
    color: #000;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
}


